<template>
<b-container fluid>
  <bo-page-title />


  <b-card v-if="isList" no-body>
    <b-card-header>
      <b-row>
        <b-col md="3">
          <date-picker placeholder="Filter Guarantee Date" id="dateGuarantee" value-type="format" format="YYYY-MM-DD" range prefix-class="crm" v-model="dateFilter" @input="doFilterDate" v-b-tooltip.hover.noninteractive="'Filter Guarantee Date'" :disabled-date="notAfterToday" />
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholder="Select an Inputter" label="label" :reduce="v => v.value.toString()" @input="doFilter()" v-model="filter.inputter" :options="inputter" :clearable="true"></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholer="All Testimonial Filter" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.ts" :options="testimonialFilter"></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholer="All Complaint Filter" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.cm" :options="complaintFilter"></v-select>
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <SearchInput :value.sync="filter.search" @search="doFilter" />
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="p-0">
      <b-table
        :fields="fields"
        :items="dataList||[]"
        :primary-key="idKey"
        :busy="!dataList"
        responsive
        show-empty
        bordered
        striped
      >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-3 text-info"></b-spinner>
            <strong class="text-info">Loading...</strong>
          </div>
        </template>
        <template #cell(number)="v">
          {{(data.per_page*(data.current_page-1))+v.index+1}}
        </template>
        <template #cell(tp_client_name)="data">
          <div><small>{{ data.item.tp_code }}</small></div>
          <div>{{ data.value }} - {{ data.item.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</div>
          <a v-if="data.item.tp_client_email" :href="'mailto: '+data.item.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ data.item.tp_client_email }}</span></a>
          <a v-if="data.item.tp_client_phone" :href="'https://web.whatsapp.com/send?phone='+data.item.tp_client_phone.replace('08', '628')" target="child" class="contact-info"><i class="fab fa-whatsapp"></i><span>{{ data.item.tp_client_phone }}</span></a>
        </template>
        <template #cell(after_sales)="data">
          <div class="contact-info text-success" v-if="data.item.tp_guarantee">
            <i class="fas fa-check-circle"></i>
            <strong>Guaranteed until {{ data.item.tp_guarantee | moment('MMM D YYYY')}}</strong>
          </div>
          <div class="contact-info text-danger" v-else>
            <i class="fas fa-times-circle"></i>
            <strong>Add Project Guarantee</strong>
          </div>
          <!-- Testimoni -->
          <div class="contact-info text-success" v-if="data.item.tp_testimoni">
            <i class="fas fa-check-circle"></i>
            <strong>Testimonial Added</strong>
          </div>
          <div class="contact-info text-danger" v-else>
            <i class="fas fa-times-circle"></i>
            <strong>Testimonial is not available</strong>
          </div>
          <div class="contact-info text-success" v-if="!data.item.count_complaint">
            <i class="fas fa-check-circle"></i>
            <strong>No Complaints</strong>
          </div>
          <div class="contact-info text-danger" v-else>
            <i class="fas fa-times-circle"></i>
            <strong>{{data.item.count_complaint}} complaints need to be resolved</strong>
          </div>
        </template>
        <template #cell(tp_client_address)="data">
          <small>{{ data.value }}</small>
        </template>
        <template #cell(tp_contact_via)="data">
          {{ contactMedia(data.value).label }}
        </template>
        <template #cell(tp_total_price)="data">
          {{ formatRupiah(data.value) }}
        </template>
        <template #cell(date)="data">
          <p v-if="data.item.tp_closing_date"><small>Closing</small>: {{ data.item.tp_closing_date }}</p>
          <p v-if="data.item.tp_date_after_sales"><small>Finish</small>: {{ data.item.tp_date_after_sales }}</p>
        </template>
        <template #cell(tp_keperluan)="data">
          <p v-if="data.item.mpct_name">Kategori: {{data.item.mpct_name}}</p>
          <span>{{data.value}}</span>
        </template>
        <template #cell(action)="data">
          <div class="align-items-center">
            <b-button
              v-if="moduleRole('add_guarantee') && !data.item.tp_guarantee"
              class="btn-icon"
              variant="success"
              v-b-tooltip.hover.noninteractive="'Add Guarantee'"
              @click="modalGuarantee(data.item.tp_code)"
            >
              <i class="fas fa-plus"></i>
            </b-button>
            <b-button
              v-if="moduleRole('add_testimonial') && !data.item.tp_testimoni"
              class="btn-icon"
              variant="info"
              v-b-tooltip.hover.noninteractive="'Add Testimonial'"
              @click="modalTestimoni(data.item.tp_code)"
            >
              <i class="fas fa-comment"></i>
            </b-button>
            <b-button
              class="btn-icon"
              variant="outline-info"
              v-b-tooltip.hover.noninteractive="'View Detail'"
              :to="{ name: $route.name, params: { pageSlug: 'detail', pageId: data.item.tp_code } }"
            ><i class="fas fa-eye"></i>
            </b-button>

            <b-button
              class="btn-icon"
              variant="outline-warning"
              v-b-tooltip.hover.noninteractive="'Edit Customer'"
              v-if="moduleRole('edit_customer')"
              @click="openModalEditCustomer(data.item)"
            ><i class="fas fa-pencil-alt"></i>
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card-body>
    <b-card-footer>
      <template v-if="(dataList||[]).length&&data.total>10">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <label for="perPage" class="mb-0 mr-2">Show</label>
              <b-form-select
                size="sm"
                id="perPage"
                class="w-auto mx-50"
                v-model="perPage"
                :options="Config.dataShownOptions"
              />
            <label for="perPage" class="mb-0 ml-2">items per page</label>
          </div>
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </div>
      </template>
    </b-card-footer>


    <b-modal
      id="addGuarantee"
      title="Add Guarantee"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormGuarantee">
        <b-form @submit.prevent="handleSubmit(addGuarantee())">
          <b-form-group label="Guarantee Until" label-for="guaranteedate">
            <date-picker type="date" value-type="format" format="YYYY-MM-DD" id="guaranteedate" prefix-class="crm" v-model="dataModal.tp_guarantee" :disabled-date="notBeforeToday" />
            <VValidate name="Guarantee Until" v-model="dataModal.tp_guarantee" rules="required" />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('addGuarantee')">Cancel</b-button>
        <b-button size="md" variant="info" @click="addGuarantee()">Add Guarantee</b-button>
      </template>
    </b-modal>

    <b-modal
      id="addTestimoni"
      title="Add Testimonial"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormTestimoni">
        <b-form @submit.prevent="handleSubmit(addTestimoni())">
          <b-form-group label="Testimonial" label-for="testimoni">
            <b-form-textarea id="testimoni" rows="3" placeholder="e.g. pemasangan sudah berjalan 50%" v-model="dataModal.tp_testimoni" />
            <VValidate name="Testimonial" v-model="dataModal.tp_testimoni" rules="required|min:5" />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('addTestimoni')">Cancel</b-button>
        <b-button size="md" variant="info" @click="addTestimoni()">Add Testimonial</b-button>
      </template>
    </b-modal>

    <b-modal
      id="editCustomer"
      title="Edit Customer"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormEditCustomer">
        <b-form @submit.prevent="handleSubmit(editCustomer())">
          <b-form-row>            
            <b-col md=12>
              <b-form-group label="Prospect Date" label-for="proSurveySchedule">
                <span>{{ dataModal.tp_date | moment("LLLL") }}</span>
              </b-form-group>
            </b-col>

            <b-col md=12>
              <b-form-group label="Customer Name" label-for="cusName">
                <b-form-input id="cusName" placeholder="e.g. Anna Puspita" v-model="dataModal.tp_client_name" />
                <VValidate name="Customer Name" v-model="dataModal.tp_client_name" :rules="{required: 1, min: 3, max: 128}" />
              </b-form-group>

              <b-form-group label="Phone Number" label-for="proNumber">
                <b-input-group prepend="+62">
                  <b-form-input id="proNumber" v-model="dataModal.tp_client_phone" type="tel" placeholder="e.g. 8565xxxxxxx" :formatter="telp" />
                </b-input-group>
                  <VValidate 
                    name="Phone Number" 
                    v-model="dataModal.tp_client_phone" 
                    :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/, required: true}" 
                  />
              </b-form-group>

              <b-form-group label-for="prEmail">
                <template #label>Email <small>(Optional)</small></template>
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text><i class="fas fa-at"></i></b-input-group-text>
                  </template>
                  <b-form-input id="proEmail" v-model="dataModal.tp_client_email" type="email" placeholder="e.g. annapuspita@mail.com" />
                </b-input-group>
                  <VValidate name="Email" rules="email" v-model="dataModal.tp_client_email" />
              </b-form-group>

              <b-form-group label="Address" label-for="proAddress">
                <b-form-textarea id="proAddress" v-model="dataModal.tp_client_address" placeholder="e.g. Jl. Sudirman No. 5 Kuningan, Jakarta Barat - DKI Jakarta" rows="3" />
                <VValidate name="Address" :rules="{ required: 1, min: 5, max: 256 }" v-model="dataModal.tp_client_address" />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('editCustomer')">Cancel</b-button>
        <b-button size="md" variant="info" @click="editCustomer()">Update</b-button>
      </template>
    </b-modal>

  </b-card>

  <template v-else>
    <Detail v-bind="dataDetail" />
  </template>
</b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'
import Detail from './Detail.vue'

export default {
  extends: GlobalVue,
  components:{Detail},
  data() {
    return {
      dateFilter: [],
      idKey:'tp_id',
      mrValidation: {},
      prospectCategory: [],
      statusSurveyResult: [
        { text: 'Closing', value: 'C' },
        { text: 'Reject', value: 'R' },
      ],
      monitoringOption: [
        { text: 'To Workshop', value: 'workshop' },
        { text: 'To Location', value: 'location' },
      ],
      statusPending: [
        { text: 'Survey', value: 'S' },
        { text: 'Closing', value: 'C' },
        { text: 'Reject', value: 'R' },
      ],
      closingStatus: [
        { text: 'Schedule', value: 'S' },
        { text: 'Running', value: 'R' },
      ],
      testimonialFilter: [
        { label: 'All Testimonial Filter', value: '' },
        { label: 'With Testimonial', value: 'Y' },
        { label: 'No Testimonial', value: 'N' },
      ],
      complaintFilter: [
        { label: 'All Complaint Filter', value: '' },
        { label: 'With Complaint', value: 'Y' },
        { label: 'No Complaint', value: 'N' },
      ],
      fields: [
        {
          key: 'number', label: '#', thStyle: {width: '5%'}
        },
        {
          key: 'tp_client_name', label: 'Customer Name', thStyle: {width: '25%'}
        },
        {
          key: 'after_sales', label: 'After Sales Service', thStyle: {width: '15%'}
        },
        {
          key: 'tp_client_address', label: 'Address', thStyle: {width: '20%'}
        },
        {
          key: 'tp_keperluan', label: 'Category & Purpose', thStyle: {width: '10%'}
        },
        {
          key: 'date', label: 'Date', thStyle: {width: '15%'}
        },
        {
          key: 'action', thStyle: {width: '10%'}
        },
      ],
      customerExists: false,
      mrCustomer: [],
      inputter: [],
      customerName: '',
      dataModal: {},
      total_all: 0,
      total_schedule: 0,
      total_running: 0,
      total_finish: 0,
      active_filter: '',
      dataDetail: {
        customerInformation: {
          tp_client_name: '',
          tp_contact_via: '',
          tp_source: '',
          tp_status_customer: 'R'
        },
        revenue: { partner: 0, bu: 0},
        revenuePercent: { partner: 0, bu: 0},
        received: { partner: 0, bu: 0},
        accountsReceivable: { partner: 0, bu: 0},
      },
      payBreak: null,
      dataCustomer: {}
    }
  },
  mounted(){
    this.apiGet()
    const a = []
    a[0] = this.$route.query['start-date'] || null
    a[1] = this.$route.query['end-date'] || null

    this.$set(this, 'dateFilter', a)
  },
  methods: {
    notAfterToday(date){
      return date > new Date()
    },
    doFilterDate(newVal){
      this.$set(this.filter, 'start-date', newVal[0] || '')
      this.$set(this.filter, 'end-date', newVal[1] || '')
      this.doFilter()
    },
    modalGuarantee(code){
      this.$set(this, 'dataModal', {code})

      this.$bvModal.show('addGuarantee')
    },
    modalTestimoni(code){
      this.$set(this, 'dataModal', {code})

      this.$bvModal.show('addTestimoni')
    },
    addGuarantee(){
      this.$refs.VFormGuarantee.validate().then(success => {
        if(!success) return

        Gen.apiRest(
            '/do/' + this.modulePage,
            { data: {type: 'addGuarantee', ...this.dataModal} },
            'POST'
          ).then(res => {
            if(res){
                this.$swal({
                  title: res.data.message,
                  text: '',
                  timer: 1500,
                  icon: 'success',
                }).then(() => {
                  this.$bvModal.hide('addGuarantee')
                  this.apiGet()
                })
            }
          }).catch(() => {
            this.$bvModal.hide('addGuarantee')
            this.apiGet()
          })
        })
    },
    addTestimoni(){
      this.$refs.VFormTestimoni.validate().then(success => {
        if(!success) return

        Gen.apiRest(
            '/do/' + this.modulePage,
            { data: {type: 'addTestimoni', ...this.dataModal} },
            'POST'
          ).then(res => {
            if(res){
                this.$swal({
                  title: res.data.message,
                  text: '',
                  timer: 1500,
                  icon: 'success',
                }).then(() => {
                  this.$bvModal.hide('addTestimoni')
                  this.apiGet()
                })
            }
          }).catch(() => {
            this.$bvModal.hide('addTestimoni')
            this.apiGet()
          })
        })
    },
    setDataModal(){
      const selectedCustomer = this.mrCustomer.find(v => v.tp_id == this.dataModal.tp_id)
      if(selectedCustomer){
        this.$set(this, 'dataCustomer', selectedCustomer)
      }
    },
    addAfterSales(){
      this.$refs.VForm.validate().then(success => {
        if(!success) return

        Gen.apiRest(
            '/do/' + this.modulePage,
            { data: {type: 'addAfterSales', ...this.dataModal} },
            'POST'
          ).then(res => {
            if(res){
                this.$swal({
                  title: res.data.message,
                  text: '',
                  timer: 1500,
                  icon: 'success',
                }).then(() => {
                  this.$bvModal.hide('addAfterSales')
                  this.apiGet()
                })
            }
          }).catch(() => {
            this.$bvModal.hide('addAfterSales')
            this.apiGet()
          })
      })
    },
    openModalMonitoring(data){
      this.$set(this, 'dataModal', {})
      this.$set(this, 'dataModal', data)

      this.$bvModal.show('monitoring')
    },
    updateRunning(){
      this.$refs.VFormRunning.validate().then(success => {
        if(!success) return
          Gen.apiRest(
              '/do/' + this.modulePage,
              { data: {type: 'updateRunning', ...this.dataModal} },
              'POST'
            ).then(res => {
            if(res){
                this.$swal({
                  title: res.data.message,
                  text: '',
                  timer: 1500,
                  icon: 'success',
                }).then(() => {
                  this.$bvModal.hide('updateRunning')
                  this.apiGet()
                })
            }
          }).catch(() => {
            this.$bvModal.hide('updateRunning')
            this.apiGet()
          })
      })
    },
    notBeforeToday(date) {
      return date ?  false : false
      // return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    closeModal(id, resetRow = true){
      this.$bvModal.hide(id)
      if(resetRow){
        this.$set(this, 'row', {})
      }
    },
    forcePayment(code){
      this.$swal({
          title: 'Are you sure to Force Repayment ?',
          showConfirmButton: true,
          showCancelButton: true,
          confirmCancelText: 'Cancel',
          confirmButtonText: 'Yes, Sure',
          icon: 'warning',
        }).then(result => {
          if(result.value){
            Gen.apiRest(
              '/do/' + this.modulePage,
              { data: {type: 'forcepayment', code} },
              'POST'
            ).then(res => {
              if(res){
                  this.$swal({
                    title: res.data.message,
                    text: '',
                    timer: 1500,
                    icon: 'success',
                  }).then(() => {
                    this.apiGet()
                  })
              }
            }).catch(() => {
              this.apiGet()
            })
          }
      })
    },
    addMonitoringResult(){
      this.$refs.VFormMonitoring.validate().then(success => {
        if(!success) return

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'update-monitoring', ...this.dataModal} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('monitoring')
              })
          }
        }).catch(() => {
            this.apiGet()
            this.$bvModal.hide('monitoring')
        })
      })
    },
    openModalEditCustomer(data){
      this.$set(this, 'dataModal', {})
      this.$set(this, 'dataModal', Object.assign({}, data))

      this.$bvModal.show('editCustomer')
    },
    editCustomer(){
      this.$refs.VFormEditCustomer.validate().then(success => {
        if(!success) return 

        this.$swal({
          title: 'Update Customer...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'update-customer', ...this.dataModal} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('editCustomer')
              })
          }
        }).catch(() => {
            this.apiGet()
            this.$bvModal.hide('editCustomer')
        })        
      })
    },
  },
  computed: {
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'filter'(){
      if('inputter' in this.filter == false){
        this.$set(this.filter, 'inputter', '')
      }
      if('ts' in this.filter == false){
        this.$set(this.filter, 'ts', '')
      }
      if('cm' in this.filter == false){
        this.$set(this.filter, 'cm', '')
      }

      const a = []
      a[0] = this.filter['start-date'] || null
      a[1] = this.filter['end-date'] || null
      this.$set(this, 'dateFilter', a)
    },
    customerExists(newVal){
      if(!newVal){
          this.$set(this.row, 'tp_client_name', '')
          this.$set(this.row, 'tp_client_type', '')
          this.$set(this.row, 'tp_client_phone', '')
          this.$set(this.row, 'tp_client_email', '')
          this.$set(this.row, 'tp_client_address', '')
      }
      this.$set(this.row, 'customer_exist', newVal)
    },
    customerName(newVal){
      this.$set(this.row, 'tp_client_name', this.mrClient.find(v => v.mc_id == newVal).mc_name)
      if(!this.customerExists) return

      const cust = this.mrClient.find(v => v.mc_id == this.customerName)
      if(cust){
        this.$set(this.row, 'tp_client_type', cust.mc_type)
        this.$set(this.row, 'tp_client_phone', cust.mc_phone)
        this.$set(this.row, 'tp_client_email', cust.mc_email)
        this.$set(this.row, 'tp_client_address', cust.mc_address)
      }
    },
  }
}
</script>